import { useState, useEffect } from "react";
import logo from "../../assets/images/android-chrome-512x512.png";
import Logout from "../../pages/Auth/Logout";
import { useAuth } from "../../hooks/AuthProvider";
import { Profile } from "../../utils/interface";

function Navbar() {
  const authContext = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [profile, setProfile] = useState<Profile>(authContext.user);

  useEffect(() => {
    let userData = authContext.getUserInfo();
    if (userData) {
      setProfile(userData);
      setIsAdmin(userData?.role);
    }
    // eslint-disable-next-line
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="shadow">
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl">
          <a href="/" className="flex items-center">
            <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap">
              The Supply Chain Game
            </span>
          </a>
          <div className="flex items-center lg:order-3">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`w-6 h-6 ${isOpen ? "hidden" : "block"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className={`w-6 h-6 ${isOpen ? "block" : "hidden"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`justify-start items-start lg:flex ${
              isOpen ? "flex" : "hidden"
            }`}
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-12 lg:mt-0">
              <li>
                <a
                  href="/background"
                  className="block text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0"
                >
                  Background
                </a>
              </li>
              <li>
                <a
                  href="/how-to-play"
                  className="block text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0"
                >
                  How To Play
                </a>
              </li>
              <li>
                <a
                  href={isAdmin ? "/admin" : "/start-game"}
                  className="block text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0"
                >
                  {isAdmin ? "Admin Page" : "Play Game"}
                </a>
              </li>
              {isAdmin && (
                <li>
                  <a
                    href={isAdmin ? "/admin-cards" : "/start-game"}
                    className="block text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0"
                  >
                    {isAdmin ? "Manage Game" : "Play Game"}
                  </a>
                </li>
              )}

              {!authContext.isLoggedIn && (
                <>
                  <li>
                    <a
                      href="/login"
                      className="block text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0"
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      href="/register"
                      className="block text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0"
                    >
                      Register
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
          {authContext.isLoggedIn &&
            window.location.pathname !== "/" &&
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/register" &&
            window.location.pathname !== "/forgot-password" && (
              <div>
                <Logout />
              </div>
            )}
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
