import { useRoutes } from "react-router-dom";
import routes from "../src/routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./hooks/AuthProvider";
// import { Provider as ReactReduxProvider } from 'react-redux';
// import store from "./state/store";
function App() {
  const routeResult = useRoutes(routes);
  return (
    <>
      <div className="min-h-screen flex flex-col overflow-auto">
        <AuthProvider>
          <ToastContainer />

          <Navbar />
          <div className="flex-1">{routeResult}</div>
          <Footer />
        </AuthProvider>
      </div>
    </>
  );
}

export default App;
