import { GoogleLogin } from "@react-oauth/google"; // Import the new GoogleLogin from @react-oauth/google
import axios, { AxiosResponse } from "axios";
import { ApiConstants } from "../../utils/api-constants";
import axiosHttp from "../../utils/axios-index";
import { useAuth } from "../../hooks/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";

function OAuthLogin({ text }: any) {
  const authContext = useAuth();

  const onLoginSuccess = (response: any) => {
    console.log("Login successful!");
    console.log(response);
    const decoded = jwtDecode(response.credential) as any;
    console.log(decoded);

    let obj = {
      email: decoded.email,
      name: decoded.name,
    };
    axiosHttp
      .post(ApiConstants.auth.googleRegister, obj)
      .then((res: AxiosResponse) => {
        toast.success("Login successfull");
        authContext.setToken(res.data.access_token);
        localStorage.setItem("accessToken", res.data.access_token);
        delete res.data.token;
        authContext.setUser(res.data.user);
        localStorage.setItem("profileData", JSON.stringify(res.data.user));
        if (res.data.user.role === "admin") {
          window.location.href = "/admin-cards";
        } else {
          window.location.href = "/start-game";
        }
      })
      .catch((err: AxiosResponse) => {
        toast.error(err.data.message);
      });
  };

  return (
    <div>
      <GoogleLogin
        onSuccess={onLoginSuccess}
        text={text as any}
        // onFailure={onLoginFailure} // Pass error handler with error parameter
      />
    </div>
  );
}

export default OAuthLogin;
