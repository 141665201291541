import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RoleSelector from "./RoleSelector";
import axios, { AxiosResponse } from "axios";
import axiosHttp from "../../utils/axios-index";
import { ApiConstants } from "../../utils/api-constants";
import { useAuth } from "../../hooks/AuthProvider";
import { Profile } from "../../utils/interface";

interface Role {
  name: string;
  image: string;
  selected: boolean;
}

interface SupplyChainManagementProps {}

const SupplyChainManagement: React.FC<SupplyChainManagementProps> = () => {
  const authContext = useAuth();

  const [profile, setProfile] = useState<Profile>(authContext.user);

  useEffect(() => {
    let userData = authContext.getUserInfo();
    if (userData) {
      setProfile(userData);
    }
    // eslint-disable-next-line
  }, []);
  const difficulties = ["Easy", "Medium", "Hard"];
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>("");
  const roles: Role[] = [
    {
      name: "Factory",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/27c589b241e3b00ab4457d759251c0156ffa5c96add4eb6b6fd0a7426b708b05?placeholderIfAbsent=true&apiKey=58de30a0377e49d19418fe19f767a1f6",
      selected: true,
    },
    {
      name: "Wholesaler",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/b274681d116efc839cbe188080af7ebd336899280333334034ded85c7b43d0ef?placeholderIfAbsent=true&apiKey=58de30a0377e49d19418fe19f767a1f6",
      selected: false,
    },
    {
      name: "Distributor",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e81606eb7d27e524ae867ce7884bf52a0664893b569f3758177cfe8407a08f1d?placeholderIfAbsent=true&apiKey=58de30a0377e49d19418fe19f767a1f6",
      selected: false,
    },
    {
      name: "Retailer",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/1672fc8d69866421c0adc13ad172e9d05fd64e373d596c0656e66cd6b2990e4f?placeholderIfAbsent=true&apiKey=58de30a0377e49d19418fe19f767a1f6",
      selected: false,
    },
  ];

  const [selectedRoleIndex, setSelectedRoleIndex] = useState<number | null>(
    null
  );
  const [gameLength, setGameLength] = useState<number>(20); // State for game length slider
  const navigate = useNavigate();

  const handleSelectRole = (index: number) => {
    setSelectedRoleIndex(index);
  };

  const handleContinue = async () => {
    if (selectedRoleIndex !== null) {
      const body = {
        playerRole: roles[selectedRoleIndex].name?.toLowerCase(),
        weeksToPlay: gameLength,
        level: selectedDifficulty,
        userId: profile?.id,
      };
      await axiosHttp
        .post(ApiConstants.game.startGame, body)
        .then((res: AxiosResponse) => {
          localStorage.setItem(
            "selectedEntity",
            roles[selectedRoleIndex].name?.toLowerCase()
          );
          localStorage.setItem("gameLength", gameLength.toString());
          localStorage.setItem("gameId", res.data.gameId);
          localStorage.setItem("performanceId", res.data.performanceId);
          localStorage.setItem("game", JSON.stringify(res.data));
        });

      navigate("/animation");
    }
  };

  return (
    <main className="flex overflow-hidden flex-col pb-72 bg-white max-md:pb-24 w-full">
      <section className="flex flex-wrap gap-6 self-start mt-9 ml-12 text-black w-full">
        <div className="flex flex-col max-md:max-w-full w-1/2">
          <h2 className="text-xl max-md:max-w-full ">
            Select the Role to play
          </h2>
          <RoleSelector
            roles={roles.map((role, index) => ({
              ...role,
              selected: index === selectedRoleIndex,
            }))}
            onSelectRole={handleSelectRole}
          />
        </div>

        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0c2a5fdbdf5dae5246d57a18bbc28d831c4b7a3ecf98d2674099e55cd0e15b59?placeholderIfAbsent=true&apiKey=58de30a0377e49d19418fe19f767a1f6"
          alt=""
          className="object-contain shrink-0 w-px aspect-[0]"
        />
        <div className="flex flex-col w-1/3 max-md:max-w-full mt-6">
          <h2 className="self-start text-xl">Select the length of the game</h2>
          <input
            type="range"
            min="20"
            max="52"
            value={gameLength}
            onChange={(e) => setGameLength(Number(e.target.value))}
            className="w-full mt-2"
          />
          <p className="text-lg font-medium mt-1">
            Game Length: {gameLength} weeks
          </p>
          <div className=" mt-8">
            <h2 className="self-start text-xl">Select the level of the game</h2>

            <div className="grid grid-cols-3 gap-4 mb-4">
              {difficulties.map((difficulty) => (
                <button
                  key={difficulty}
                  className={`p-3 border rounded-lg transition transform hover:scale-105 ${
                    selectedDifficulty === difficulty
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                  onClick={() => setSelectedDifficulty(difficulty)}
                >
                  {difficulty}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>

      <button
        className="rounded-lg bg-green-500 w-40 mt-10 ml-12 p-2 text-white"
        onClick={handleContinue}
      >
        Continue
      </button>
    </main>
  );
};

export default SupplyChainManagement;
