import React from "react";

interface Role {
  name: string;
  image: string;
  selected: boolean;
}

interface RoleSelectorProps {
  roles: Role[];
  onSelectRole: (index: number) => void;
}

const RoleSelector: React.FC<RoleSelectorProps> = ({ roles, onSelectRole }) => {
  return (
    <div className="flex flex-col mt-4 w-full text-2xl whitespace-nowrap max-md:max-w-full">
      {roles.map((role, index) => (
        <div
          key={index}
          className={`flex overflow-hidden flex-wrap gap-5 justify-between px-6 py-1 w-full ${
            role.selected
              ? "font-bold text-blue-500 bg-blue-50 border-2 border-blue-500 shadow-[0px_0px_8px_rgba(49,131,255,0.24)]"
              : "bg-white border border-black"
          } rounded-xl border-solid ${
            index > 0 ? "mt-3" : ""
          } max-md:pr-5 max-md:max-w-full`}
          onClick={() => onSelectRole(index)}
        >
          <div className="flex gap-9">
            <img
              loading="lazy"
              src={role.image}
              alt={`${role.name} icon`}
              className="object-contain shrink-0 aspect-square w-[86px]"
            />
            <div className="my-auto">{role.name}</div>
          </div>
          {role.selected && (
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3da7d656f7251836ab5bb648872c995b093119eaf0bb7462ed8b359babb8624c?placeholderIfAbsent=true&apiKey=58de30a0377e49d19418fe19f767a1f6"
              alt="Selected indicator"
              className="object-contain shrink-0 my-auto w-8 aspect-square rounded-[132px]"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default RoleSelector;
