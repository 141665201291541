import logo from "../../assets/images/android-chrome-192x192.png";

export default function Footer() {
  return (
    <>
      <footer className="bg-gray-50 rounded-lg shadow  bottom-0 left-0 right-0 p-0 mb-0">
        <div className="w-full max-w-screen-xl mx-auto p-3">
          <div className="sm:flex sm:items-center sm:justify-between">
            <a
              href="#"
              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="h-8" alt="Open University Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap">
                Expert SCM
              </span>
            </a>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0"></ul>
          </div>
          <hr />
          <span className="block text-sm text-gray-500 sm:text-center">
            © 2024{" "}
            <a href="#" className="hover:underline">
              Expert SCM a unit of 'ThoughtWare Training Pvt Ltd'
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
}
