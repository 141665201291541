import React, { useEffect, useState } from "react";
import Mail from "./Mail.png";
import factory2 from "./Factory (2).png";
import factory3 from "./Factory (3).png";
import factory from "./Factory.png";
import image from "./Factory (1).png";
import "./index.css"; // Assuming you're using Tailwind CSS
import Box from "./Box.png";
import Modal from "../../components/SecondStepGame/Modal";
import { AxiosResponse } from "axios";
import axiosHttp from "../../utils/axios-index";
import { ApiConstants } from "../../utils/api-constants";
export type GameData = {
  currentWeek: number;
  factory: {
    stock: number;
    backlog: number;
    delayQueue: number[];
    totalCost: number;
  };
  wholesaler: {
    stock: number;
    backlog: number;
    delayQueue: number[];
    totalCost: number;
  };
  distributor: {
    stock: number;
    backlog: number;
    delayQueue: number[];
    totalCost: number;
  };
  retailer: {
    stock: number;
    backlog: number;
    delayQueue: number[];
    totalCost: number;
  };
  totalCost: number;
  totalStock: number;
};
export const MacbookAir = (): JSX.Element => {
  const [isDistributor, setisDistributor] = useState(false);
  const [isFactory, setisFactory] = useState(false);
  const [isRetailer, setisRetailer] = useState(false);
  const [orders, setOrders] = useState<number>();
  const [isAnimating, setIsAnimating] = useState("");
  const [isAnimating2, setIsAnimating2] = useState("");
  const [isAnimating3, setIsAnimating3] = useState("");
  const [isAnimating41, setIsAnimating41] = useState("");
  const [isAnimating42, setIsAnimating42] = useState("");
  const [modalOpen, setIsModalOpen] = useState(false);
  const [gameData, setGameData] = useState<any>();
  const selectedEntity = localStorage.getItem("selectedEntity") as string;

  const setCurrentEntity = () => {
    if (selectedEntity === "distributor") {
      setisDistributor(true);
    } else if (selectedEntity === "factory") {
      setisFactory(true);
    } else if (selectedEntity === "retailer") {
      setisRetailer(true);
    }
  };
  useEffect(() => {
    setCurrentEntity();
  }, []);

  useEffect(() => {
    const game = localStorage.getItem("game");
    if (game) {
      const games = JSON.parse(game);
      setGameData(games);
    }
  }, []);
  const handleOutgoingOrder = async (order: number) => {
    setOrders(order);
    const performanceId = localStorage.getItem("performanceId") as any;
    await axiosHttp
      .post(ApiConstants.game.playTurn, {
        entityType: selectedEntity?.toLowerCase(),
        orderAmount: order,
        performanceId: performanceId,
      })
      .then((res: AxiosResponse) => {
        setGameData(res.data);
      });

    setIsAnimating("animate-slide-left");
    setTimeout(() => {
      setIsAnimating("");
      setIsAnimating2("slide-top-right");
      setTimeout(() => {
        setIsAnimating3("animate-slide-right");
        setTimeout(() => {
          setIsAnimating3("");
          setIsAnimating2("");

          setIsAnimating41("animate-41");
          setIsAnimating42("animate-42");
          setTimeout(() => {
            setIsAnimating41("");
            setIsAnimating42("");
          }, 1000);
        }, 1000);
      }, 1000);
    }, 1000);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Type Guard: Ensure selected entity key is valid and that the selected entity has 'totalCost'
  // const selectedEntityKey = selectedEntity?.toLowerCase() as keyof GameData;
  const entityKey = selectedEntity?.toLowerCase() as keyof GameData; // This ensures `selectedEntity` is cast to a valid key
  const entity = entityKey && gameData ? gameData[entityKey] : null; // Ensure entity exists

  // gameData[selectedEntity?.toLowerCase() as keyof GameData] || {};
  console.log(selectedEntity, 110);

  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white w-[1280px] h-[832px] relative">
        {/* <div className="absolute w-[1280px] h-[54px] top-0 left-0 bg-white shadow-[0px_4px_4px_#00000014]">
          <div className="absolute top-3.5 left-12 [font-family:'Product_Sans-Bold',Helvetica] font-bold text-[#3183ff] text-xl tracking-[0] leading-[normal] whitespace-nowrap">
            Supply Chain Management
          </div>
        </div> */}

        <div className="absolute w-[84px] h-[88px] top-[88px] left-12 bg-white rounded-[10px] overflow-hidden border border-solid border-primary-200">
          <div className="absolute top-[11px] left-[19px] [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm tracking-[-0.14px] leading-[16.8px] whitespace-nowrap">
            Period
          </div>

          <div className="w-[60px] h-[42px] top-[34px] left-3 bg-primary-100 absolute rounded">
            <div className="top-[7px] left-[22px] [font-family:'Sora-SemiBold',Helvetica] font-semibold text-xl absolute text-secondary-950 text-center tracking-[0] leading-[normal]">
              {gameData?.currentWeek || 0}
            </div>
          </div>
        </div>

        <div className="absolute w-96 h-[88px] top-[88px] left-[148px] bg-white rounded-[10px] overflow-hidden border border-solid border-primary-200">
          <div className="absolute top-[11px] left-3 [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm tracking-[-0.14px] leading-[16.8px] whitespace-nowrap">
            Individual Performance
          </div>

          <div className="absolute top-[47px] left-3 [font-family:'Sora-Light',Helvetica] font-light text-secondary-950 text-xs tracking-[-0.12px] leading-[14.4px] whitespace-nowrap">
            Total Cost
          </div>

          <div className="w-[81px] h-[42px] top-[34px] left-[77px] bg-primary-100 absolute rounded">
            <div className="absolute top-[7px] left-[22px] [font-family:'Sora-SemiBold',Helvetica] font-semibold text-secondary-950 text-xl text-center tracking-[0] leading-[normal]">
              {
                // @ts-ignore
                entity?.totalCost || 0
              }
            </div>
          </div>
        </div>

        <div className="absolute w-[684px] h-[88px] top-[88px] left-[548px] bg-white rounded-[10px] overflow-hidden border border-solid border-primary-200">
          <div className="absolute top-[11px] left-3 [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm tracking-[-0.14px] leading-[16.8px] whitespace-nowrap">
            Supply Chain Management
          </div>

          <div className="inline-flex items-center gap-1 absolute top-[34px] left-3">
            <div className="relative w-fit [font-family:'Sora-Light',Helvetica] font-light text-secondary-950 text-xs tracking-[-0.12px] leading-[14.4px] whitespace-nowrap">
              Total Cost
            </div>

            <div className="relative w-[81px] h-[42px] bg-primary-100 rounded">
              <div className="absolute top-[7px] left-5 [font-family:'Sora-SemiBold',Helvetica] font-semibold text-secondary-950 text-xl text-center tracking-[0] leading-[normal]">
                {gameData?.totalCost || 0}
              </div>
            </div>
          </div>

          <div className="inline-flex items-center gap-1 absolute top-[34px] left-[170px]">
            <div className="relative w-fit [font-family:'Sora-Light',Helvetica] font-light text-secondary-950 text-xs tracking-[-0.12px] leading-[14.4px] whitespace-nowrap">
              Total stock:
            </div>

            <div className="relative w-[81px] h-[42px] bg-primary-100 rounded">
              <div className="top-[7px] left-[19px] [font-family:'Sora-SemiBold',Helvetica] font-semibold text-xl absolute text-secondary-950 text-center tracking-[0] leading-[normal]">
                {gameData?.totalStock || 0}
              </div>
            </div>
          </div>

          <div className="inline-flex items-center gap-1 absolute top-[34px] left-[335px]">
            <div className="relative w-fit [font-family:'Sora-Light',Helvetica] font-light text-secondary-950 text-xs tracking-[-0.12px] leading-[14.4px] whitespace-nowrap">
              Service Level
            </div>

            <div className="relative w-[81px] h-[42px] bg-primary-100 rounded">
              <div className="absolute top-[7px] left-3 [font-family:'Sora-SemiBold',Helvetica] font-semibold text-secondary-950 text-xl text-center tracking-[0] leading-[normal]">
                100%
              </div>
            </div>
          </div>
        </div>

        <div className="absolute w-[1184px] h-[351px] top-[245px] left-12 bg-white rounded-[10px] overflow-hidden border-[0.5px] border-solid border-black">
          <div className="absolute w-[174px] h-[174px] top-[89px] left-[349px] rounded-lg">
            <img
              className={`${
                isDistributor
                  ? "left-[313px]"
                  : isFactory
                  ? "left-[-313px]"
                  : isRetailer
                  ? "left-[626px]"
                  : ""
              } !absolute !w-[45px] !h-[45px] !top-[65px] ${isAnimating} `}
              src={Mail}
              alt="Mail Icon"
            />

            <div className="flex flex-col w-[174px] items-center justify-center gap-0.5 p-3 absolute top-0 left-0 bg-primary-50 rounded-lg border-[0.5px] border-solid border-primary-200">
              <img
                className="relative w-[120.53px] h-[120.53px] object-cover"
                alt="Factory"
                src={factory2}
              />

              <div className="relative w-fit [font-family:'Product_Sans-Regular',Helvetica] font-normal text-black text-[22px] tracking-[0] leading-[normal]">
                Wholesalers
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[174px] items-center justify-center gap-0.5 p-3 absolute top-[89px] left-[662px] bg-primary-50 rounded-lg border-[0.5px] border-solid border-primary-200">
            <img
              className="relative w-[120.53px] h-[120.53px] object-cover"
              alt="Factory"
              src={factory}
            />

            <div className="relative w-fit [font-family:'Product_Sans-Regular',Helvetica] font-normal text-black text-[22px] tracking-[0] leading-[normal]">
              Distributor
            </div>
          </div>

          <div
            className={` flex flex-col w-[174px] items-center justify-center gap-0.5 p-3 absolute top-[89px] left-9 bg-primary-50 rounded-lg border-[0.5px] border-solid border-primary-200`}
          >
            <img
              className={`${
                isDistributor
                  ? "left-[438px]"
                  : isFactory
                  ? "left-[-188px]"
                  : isRetailer
                  ? "left-[751px]"
                  : "left-[125px]"
              } !absolute !w-[45px] !h-[45px] !top-[65px] ${isAnimating3} `}
              src={Box}
              alt="Box Icon"
            />

            <img
              className="relative w-[120.53px] h-[120.53px] object-cover"
              alt="Factory"
              src={image}
            />

            <div className="relative w-fit [font-family:'Product_Sans-Regular',Helvetica] font-normal text-black text-[22px] tracking-[0] leading-[normal]">
              Factory
            </div>
          </div>

          <div className="flex flex-col w-[174px] items-center justify-center gap-0.5 p-3 absolute top-[89px] left-[975px] bg-primary-50 rounded-lg border-[0.5px] border-solid border-primary-200">
            <img
              className="relative w-[120.53px] h-[120.53px] object-cover"
              alt="Factory"
              src={factory3}
            />

            <div className="relative w-fit [font-family:'Product_Sans-Regular',Helvetica] font-normal text-black text-[22px] tracking-[0] leading-[normal]">
              Retailer
            </div>
          </div>

          <div
            className={`${
              isDistributor
                ? "left-[616px]"
                : isFactory
                ? "left-[-10px]"
                : isRetailer
                ? "left-[929px]"
                : "left-[303px]"
            } w-[38px] h-7 top-[89px] bg-primary-200 absolute rounded`}
          >
            <div className="top-1 left-3.5 [font-family:'Sora-Medium',Helvetica] font-medium text-sm absolute text-secondary-950 text-center tracking-[0] leading-[normal]">
              {/* @ts-ignore */}
              {entity?.backlog}
            </div>
          </div>

          <div
            className={`${
              isDistributor
                ? "left-[706px]"
                : isFactory
                ? "left-[80px]"
                : isRetailer
                ? "left-[1019px]"
                : "left-[393px]"
            } w-[38px] h-7 top-[53px] bg-primary-200 absolute rounded`}
          >
            <div className="absolute top-1 left-1.5 [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm text-center tracking-[0] leading-[normal]">
              {/* @ts-ignore */}
              {entity?.stock}
            </div>
          </div>

          <div
            className={` ${isAnimating2} ${isAnimating42} ${
              isDistributor
                ? "left-[706px]"
                : isFactory
                ? "left-[80px]"
                : isRetailer
                ? "left-[1019px]"
                : "left-[393px]"
            } w-[38px] h-7 top-[271px] bg-primary-200 absolute rounded`}
          >
            <div className="absolute top-1 left-1.5 [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm text-center tracking-[0] leading-[normal]">
              {/* {gameData.delays} 1st position of  delays array  */}
              {/* @ts-ignore */}
              {entity?.delayQueue[2]}
            </div>
          </div>

          <div
            className={`${
              isDistributor
                ? "left-[1019px]"
                : isFactory
                ? "left-[393px]"
                : isRetailer
                ? "left-[1332px]"
                : "left-[706px]"
            } w-[38px] h-7 top-[271px] bg-primary-200 absolute rounded  ${isAnimating2} ${isAnimating42} `}
          >
            <div className="top-1 left-[5px] [font-family:'Sora-Medium',Helvetica] font-medium text-sm absolute text-secondary-950 text-center tracking-[0] leading-[normal]">
              {/* 3rd position of delays array  */}
              {/* @ts-ignore */}
              {entity?.delayQueue[0]}
            </div>
          </div>

          <div
            className={`${
              isDistributor
                ? "left-[616px]"
                : isFactory
                ? "left-[-10px]"
                : isRetailer
                ? "left-[929px]"
                : "left-[303px]"
            } w-[38px] h-7 top-[124px] bg-primary-200 absolute rounded`}
          >
            <div className="absolute top-1 left-1.5 [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm text-center tracking-[0] leading-[normal]">
              {/* {current stock} */}
              {/* @ts-ignore */}
              {entity?.stock}
            </div>
          </div>

          <div
            className={`${
              isDistributor
                ? "left-[662px]"
                : isFactory
                ? "left-[36px]"
                : isRetailer
                ? "left-[975px]"
                : "left-[349px]"
            } w-[38px] h-7 top-[53px] left-[349px] bg-primary-200 absolute rounded`}
          >
            <div className="absolute top-1 left-1.5 [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm text-center tracking-[0] leading-[normal]">
              {/* {incoming order} */}
              {orders || 0}
            </div>
          </div>

          <div
            className={`${
              isDistributor
                ? "left-[662px]"
                : isFactory
                ? "left-[36px]"
                : isRetailer
                ? "left-[975px]"
                : "left-[349px]"
            } w-[38px] h-7 top-[271px] left-[349px] bg-primary-200 absolute rounded ${isAnimating41}`}
          >
            <div className="absolute top-1 left-1.5 [font-family:'Sora-Medium',Helvetica] font-medium text-secondary-950 text-sm text-center tracking-[0] leading-[normal]">
              {/* oth delay position */}
              {/* @ts-ignore */}
              {entity?.delayQueue[3]}
            </div>
          </div>

          <div
            className={`${
              isDistributor
                ? "left-[975px]"
                : isFactory
                ? "left-[349px]"
                : isRetailer
                ? "left-[1288px]"
                : "left-[662px]"
            } w-[38px] h-7 top-[271px] bg-primary-200 absolute rounded ${isAnimating41}`}
          >
            <div className="top-1 left-[5px] [font-family:'Sora-Medium',Helvetica] font-medium text-sm absolute text-secondary-950 text-center tracking-[0] leading-[normal]">
              {/* 2nd delay positon */}
              {/* @ts-ignore */}
              {entity?.delayQueue[1]}
            </div>
          </div>
        </div>

        <div className="inline-flex items-center gap-2 absolute top-[200px] left-[664px]">
          <div
            onClick={openModal}
            className="inline-flex items-center justify-center gap-2.5 px-5 py-2.5 relative flex-[0_0_auto] bg-white rounded-lg border border-solid border-[#3183ff] cursor-pointer"
          >
            <div className="relative w-fit mt-[-1.00px] [font-family:'Product_Sans-Regular',Helvetica] font-normal text-[#3183ff] text-sm text-center tracking-[0] leading-[normal]">
              Next Period
            </div>
          </div>
          <a
            href="/start-game"
            className="inline-flex items-center justify-center gap-2.5 px-5 py-2.5 relative flex-[0_0_auto] bg-white rounded-lg border border-solid border-[#3183ff] cursor-pointer"
          >
            <div className="relative w-fit mt-[-1.00px] [font-family:'Product_Sans-Regular',Helvetica] font-normal text-[#3183ff] text-sm text-center tracking-[0] leading-[normal]">
              Reset Game
            </div>
          </a>
          <Modal
            isOpen={modalOpen}
            onClose={() => setIsModalOpen(false)}
            entityType={selectedEntity}
            setOutgoingOrder={handleOutgoingOrder}
          />
        </div>
      </div>
    </div>
  );
};
