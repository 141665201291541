import React, { useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  entityType: string;
  setOutgoingOrder: (order: number) => void;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  entityType,
  setOutgoingOrder,
}) => {
  const [orderAmount, setOrderAmount] = useState(0);

  const handleSubmit = () => {
    setOutgoingOrder(orderAmount);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-xl font-bold mb-4">Place Order for {entityType}</h2>
        <input
          type="number"
          value={orderAmount}
          onChange={(e) => setOrderAmount(Number(e.target.value))}
          className="mt-4 border border-gray-300 p-2 rounded"
          placeholder="Enter your order"
        />

        <div className="mt-4 flex justify-end gap-2">
          <button
            className="bg-gray-300 text-black py-2 px-4 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded"
            onClick={handleSubmit}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
