import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import Loading from "../pages/Loading";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import { MacbookAir } from "../pages/temporary/animation";
import SupplyChainManagement from "../pages/temporary/playgame";
import ResetPassword from "../pages/Auth/ResetPassword";
import ResetPasswordEmail from "../pages/Auth/ResetPasswordEmail";
// import SupplyChainManagement from "../pages/temporary/animation";

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loadable(lazy(() => import("../pages/Home")));
const Background = Loadable(lazy(() => import("../pages/Background")));
const HowToPlay = Loadable(lazy(() => import("../pages/HowToPlay")));
const StartGame = Loadable(lazy(() => import("../pages/PlayGame")));
const Admin = Loadable(lazy(() => import("../pages/Admin")));
const AdminPage = Loadable(lazy(() => import("../pages/Admin/cards")));

const routes: RouteObject[] = [
  {
    path: "/",
    index: true,
    element: <Home />,
  },
  {
    path: "/background",
    index: true,
    element: <Background />,
  },
  {
    path: "/how-to-play",
    index: true,
    element: <HowToPlay />,
  },
  {
    path: "/start-game",
    index: true,
    element: <SupplyChainManagement />,
  },
  {
    path: "/login",
    index: true,
    element: <Login />,
  },
  {
    path: "/forgot-password/",
    index: true,
    element: <ResetPasswordEmail />,
  },
  {
    path: "/reset-password",
    index: true,
    element: <ResetPassword />,
  },
  {
    path: "/register",
    index: true,
    element: <Register />,
  },
  {
    path: "animation",
    index: true,
    element: <MacbookAir />,
    // element: <SupplyChainManagement />,
  },
  {
    path: "/admin",
    index: true,
    element: <Admin />,
  },
  {
    path: "/admin-cards",
    index: true,
    element: <AdminPage />,
  },
];

export default routes;
