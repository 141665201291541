export const ApiConstants = {
  baseApiUrl: "https://supply-chain-bee.onrender.com/",
  // baseApiUrl: "http://localhost:5020/",
  auth: {
    register: "auth/register",
    login: "auth/login",
    forgotPassword: "auth/forgot-password",
    resetPassword: "auth/reset-password",
    googleRegister: "auth/google/register",
  },
  googleAccessToken: (access_token: string) =>
    `https://oauth2.googleapis.com/tokeninfo?id_token=${access_token}`,
  users: {
    create: "users",
    get: () => `users`,
    getById: (id: string) => `users/${id}`,
    updateById: (id: string) => `users/${id}`,
    deleteById: (id: string) => `users/${id}`,
  },
  game: {
    create: "game",
    getState: "game/getState",
    get: () => `game`,
    getById: (id: string) => `game/${id}`,
    updateById: (id: string) => `game/${id}`,
    deleteById: (id: string) => `game/${id}`,
    startGame: "game/start",
    playTurn: "game/playTurn",
  },
};
